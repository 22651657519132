import React from "react";
import { Helmet } from "react-helmet";
import CustomTabs from "../../components/CustomTabs";
import Layout from "../../components/layout";
import * as baseStyles from "../index.module.css";
import * as styles from "./styles.module.css";

// images
import dejavoo_z8_image from "../../images/dejavu_z8.png";
import dejavoo_z9_image from "../../images/dejavu_z9.png";
import desk5000 from "../../images/desk5000.webp";
import desk3500 from "../../images/desk3500.png";
import move5000 from "../../images/move5000.png";

export default function StandardTerminals() {
  return (
    <Layout>
      <Helmet>
        <title>Standard terminals | MyEZSolutions</title>
      </Helmet>

      <div className={baseStyles.pageContent}>
        <div className={baseStyles.pageTitle}>
          <h1>Standard Terminals</h1>
          <p>
            Browse our range of standard terminals and find the one that suits
            all your uses the best.
          </p>
        </div>

        <div className={baseStyles.pageContentInner}>
          <div className={styles.productsContainer}>
            <CustomTabs
              tabs={[
                {
                  title: "Ingenico Desk 3500",
                  regions: ["en_US"],
                  description:
                    "The Desk/3500 offers a seamless payment experience. It leverages the Telium application portfolio and complies with future security standards.",
                  img: desk3500,
                  specs: [
                    ["OS", "Telium Tetra OS"],
                    ["Card Readers", "Magstripe, Smartcard, Contactless"],
                    [
                      "Display",
                      "2.8” color display, backlit, QVGA (320x240 pixels)",
                    ],
                    ["Power supply", "24W"],
                    ["Keypad", "20 ergonomic keys, raised marking, backlit"],
                    ["Audio", "Buzzer"],
                    ["SAM", "2"],
                    ["Thermal Printer", "20 l/s Speed with Paper Roll Cage"],
                    [
                      "Terminal Connectivity",
                      <>
                        WiFi
                        <br />
                        Dial-up modem
                        <br />
                        Ethernet 10/100 Base T
                      </>,
                    ],
                    [
                      "Terminal Connections",
                      <>
                        1 USB Host <br />
                        1 USB Slave <br />
                        Dedicated power jack <br />1 RS232
                      </>,
                    ],
                    ["Terminal size", "7.3 x 2.6 x 3.2” (187x82x68 mm)"],
                    ["Security", "PCI-PTS 4.x"]
                  ],
                },
                {
                  title: "Ingenico Desk 5000",
                  img: desk5000,
                  description: (
                    <>
                      <p>
                        Businesses. are always looking for ways to do things
                        faster, better and more efficiently. They want to create
                        unique customer experiences. that will help them secure
                        sales, increase conversions and promote their brand.
                      </p>
                      <p>
                        What's the solution? Ingenico's new Desk/5000, a fully
                        integrated payment terminal with best-in-class payment
                        technology, top-notch security and support for all
                        payment options.
                      </p>
                      <p>
                        Offer your clients a seamless payment experience on a
                        remarkably intuitive interface with a dedicated card
                        reader for faster NFC payments.
                      </p>
                      <p>
                        The best part? A large colour touchscreen with
                        multimedia capabilities and a variety of business
                        applications that will soon be enabled.
                      </p>
                      <p>
                        <b>
                          There's no question about it - business owners who
                          embrace technology do things faster, better and more
                          efficiently.
                        </b>
                      </p>
                    </>
                  ),
                  specs: [
                    ["OS", "Telium Tetra OS"],
                    ["Card Readers", "Magstripe, Smartcard, Contactless"],
                    ["Display", '3.5" colour screen'],
                    ["Touchscreen", "Finger & Stylus"],
                    ["Keypad", "16 Hard Top Keys"],
                    [
                      "Audio",
                      <>
                        Audio Jack - Stereo
                        <br />
                        Speaker - Mono
                      </>,
                    ],
                    ["Video", "H.264 Video Accelerator"],
                    ["Thermal Printer", "20 l/s Speed with Paper Roll Cage"],
                    [
                      "Terminal Connectivity",
                      <>
                        WiFi
                        <br />
                        Bluetooth Class 2 (some models only)
                        <br />
                        Ethernet 10/100 Base T
                      </>,
                    ],
                    [
                      "Terminal Connections",
                      "USB & dedicated power jack (32W)",
                    ],
                    ["Security", "PCI PTS 5.X Online and Offline"],
                  ],
                  features: [
                    {
                      icon: (
                        <svg viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M21,11C21,16.55 17.16,21.74 12,23C6.84,21.74 3,16.55 3,11V5L12,1L21,5V11M12,21C15.75,20 19,15.54 19,11.22V6.3L12,3.18L5,6.3V11.22C5,15.54 8.25,20 12,21M14.8,11V9.5C14.8,8.1 13.4,7 12,7C10.6,7 9.2,8.1 9.2,9.5V11C8.6,11 8,11.6 8,12.2V15.7C8,16.4 8.6,17 9.2,17H14.7C15.4,17 16,16.4 16,15.8V12.3C16,11.6 15.4,11 14.8,11M13.5,11H10.5V9.5C10.5,8.7 11.2,8.2 12,8.2C12.8,8.2 13.5,8.7 13.5,9.5V11Z"
                          />
                        </svg>
                      ),
                      title: "Security",
                      description:
                        "Offer a simpler checkout experience. Increase your sales beyond brick-and-mortar.",
                    },
                    {
                      icon: (
                        <svg viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M20 4H4C2.9 4 2 4.89 2 6V18C2 19.11 2.9 20 4 20H13.09C13.03 19.67 13 19.34 13 19C13 15.69 15.69 13 19 13C20.06 13 21.09 13.28 22 13.81V6C22 4.89 21.11 4 20 4M20 11H4V8H20M17.75 22L15 19L16.16 17.84L17.75 19.43L21.34 15.84L22.5 17.25L17.75 22"
                          />
                        </svg>
                      ),
                      title: "Magstripe",
                      description:
                        "Accept phone orders and process card-not-present payments.",
                    },
                    {
                      icon: (
                        <svg viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M18,11H6A2,2 0 0,0 4,13V21A2,2 0 0,0 6,23H18A2,2 0 0,0 20,21V13A2,2 0 0,0 18,11M18,21H6V17H18V21M18,15H6V13H18V15M4.93,4.92L6.34,6.33C9.46,3.2 14.53,3.2 17.66,6.33L19.07,4.92C15.17,1 8.84,1 4.93,4.92M7.76,7.75L9.17,9.16C10.73,7.6 13.26,7.6 14.83,9.16L16.24,7.75C13.9,5.41 10.1,5.41 7.76,7.75Z"
                          />
                        </svg>
                      ),
                      title: "Contactless",
                      description:
                        "Simplify your business income management. See all your incoming payments in one place.",
                    },
                    {
                      icon: (
                        <svg viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M19.5 3.5L18 2L16.5 3.5L15 2L13.5 3.5L12 2L10.5 3.5L9 2L7.5 3.5L6 2L4.5 3.5L3 2V22L4.5 20.5L6 22L7.5 20.5L9 22L10.5 20.5L12 22L13.5 20.5L15 22L16.5 20.5L18 22L19.5 20.5L21 22V2L19.5 3.5M19 19.09H5V4.91H19V19.09M6 15H18V17H6M6 11H18V13H6M6 7H18V9H6Z"
                          />
                        </svg>
                      ),
                      title: "Smart Card",
                      description:
                        "Award and track customer rewards. Keep customers coming back for repeat purchases through points and discounts.",
                    },
                    {
                      icon: (
                        <svg viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M7,1A2,2 0 0,0 5,3V21A2,2 0 0,0 7,23H17A2,2 0 0,0 19,21V3A2,2 0 0,0 17,1H7M7,4H17V20H7V4M9,6V10H10.5V7.5H13V6H9M13.5,14V16.5H11V18H15V14H13.5Z"
                          />
                        </svg>
                      ),
                      title: "Colour Screen",
                      description:
                        "Communicate with your customers and get quick insights. Enjoy the convenience of an integrated messaging & feedback tool",
                    },
                    {
                      icon: (
                        <svg viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M14.71,7.71L10.41,12L14.71,16.29L9,22H8V14.41L3.41,19L2,17.59L7.59,12L2,6.41L3.41,5L8,9.59V2H9L14.71,7.71M10,5.83V9.59L11.88,7.71L10,5.83M11.88,16.29L10,14.41V18.17L11.88,16.29M22,8H20V11H18V8H16L19,4L22,8M22,16L19,20L16,16H18V13H20V16H22Z"
                          />
                        </svg>
                      ),
                      title: "Bluetooth",
                      description:
                        "Offering Bluetooth connectivity to virtually any iOS, Android, or Windows-based mobile device",
                    },
                  ],
                },
                {
                  title: "Ingenico Move 5000",
                  img: move5000,
                  description: (
                    <>
                      <p>
                        Whether you operate a taxi, restaurant or mobile
                        business, the Ingenico Move/5000 will blow you away! Not
                        only is it portable and secure, it also supports all
                        forms of electronic payment and delivers a top-notch
                        payment experience to you and your customers.
                      </p>
                      <p>
                        No matter where your business takes you, the Move/5000
                        covers the full spectrum of wireless connectivity.
                        Accept payments on the shop floor, at events or right in
                        your vehicle, all while reducing communication costs.{" "}
                      </p>
                      <p>
                        The best part? The Move/5000 provides an amazing user
                        experience through a remarkably intuitive interface,
                        featuring powerful multimedia capabilities and a large
                        3.5-inch colour touchscreen.
                      </p>
                      <p>
                        <b>
                          There's no question about it, the Ingenico Move/5000
                          is the most powerful wireless device on the market
                          today!
                        </b>
                      </p>
                    </>
                  ),
                  specs: [
                    ["OS", "Telium Tetra OS"],
                    ["Card Readers", "Magstripe, Smartcard, Contactless"],
                    ["Display", '3.5" colour screen'],
                    ["Touchscreen", "Finger & Stylus"],
                    ["Keypad", "16 Hard Top Keys"],
                    [
                      "Audio",
                      <>
                        Audio Jack - Stereo
                        <br />
                        Speaker - Mono
                      </>,
                    ],
                    ["Video", "H.264 Video Accelerator"],
                    ["Data Capture", "Positioning, Barcode Reader, Camera"],
                    [
                      "Terminal Connectivity",
                      <>
                        4G
                        <br />
                        WiFi and/or Bluetooth
                      </>,
                    ],
                    [
                      "Terminal Connections",
                      "USB & dedicated power jack (32W)",
                    ],
                    ["Security", "PCI PTS 5.X Online and Offline"],
                    ["Accessories", "Car Charger, Docking Station"],
                  ],
                  features: [
                    {
                      icon: (
                        <svg viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M21,11C21,16.55 17.16,21.74 12,23C6.84,21.74 3,16.55 3,11V5L12,1L21,5V11M12,21C15.75,20 19,15.54 19,11.22V6.3L12,3.18L5,6.3V11.22C5,15.54 8.25,20 12,21M14.8,11V9.5C14.8,8.1 13.4,7 12,7C10.6,7 9.2,8.1 9.2,9.5V11C8.6,11 8,11.6 8,12.2V15.7C8,16.4 8.6,17 9.2,17H14.7C15.4,17 16,16.4 16,15.8V12.3C16,11.6 15.4,11 14.8,11M13.5,11H10.5V9.5C10.5,8.7 11.2,8.2 12,8.2C12.8,8.2 13.5,8.7 13.5,9.5V11Z"
                          />
                        </svg>
                      ),
                      title: "Security",
                      description:
                        "Offer a simpler checkout experience. Increase your sales beyond brick-and-mortar.",
                    },
                    {
                      icon: (
                        <svg viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M20 4H4C2.9 4 2 4.89 2 6V18C2 19.11 2.9 20 4 20H13.09C13.03 19.67 13 19.34 13 19C13 15.69 15.69 13 19 13C20.06 13 21.09 13.28 22 13.81V6C22 4.89 21.11 4 20 4M20 11H4V8H20M17.75 22L15 19L16.16 17.84L17.75 19.43L21.34 15.84L22.5 17.25L17.75 22"
                          />
                        </svg>
                      ),
                      title: "Magstripe",
                      description:
                        "Accept phone orders and process card-not-present payments.",
                    },
                    {
                      icon: (
                        <svg viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M18,11H6A2,2 0 0,0 4,13V21A2,2 0 0,0 6,23H18A2,2 0 0,0 20,21V13A2,2 0 0,0 18,11M18,21H6V17H18V21M18,15H6V13H18V15M4.93,4.92L6.34,6.33C9.46,3.2 14.53,3.2 17.66,6.33L19.07,4.92C15.17,1 8.84,1 4.93,4.92M7.76,7.75L9.17,9.16C10.73,7.6 13.26,7.6 14.83,9.16L16.24,7.75C13.9,5.41 10.1,5.41 7.76,7.75Z"
                          />
                        </svg>
                      ),
                      title: "Contactless",
                      description:
                        "Simplify your business income management. See all your incoming payments in one place.",
                    },
                    {
                      icon: (
                        <svg viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M19.5 3.5L18 2L16.5 3.5L15 2L13.5 3.5L12 2L10.5 3.5L9 2L7.5 3.5L6 2L4.5 3.5L3 2V22L4.5 20.5L6 22L7.5 20.5L9 22L10.5 20.5L12 22L13.5 20.5L15 22L16.5 20.5L18 22L19.5 20.5L21 22V2L19.5 3.5M19 19.09H5V4.91H19V19.09M6 15H18V17H6M6 11H18V13H6M6 7H18V9H6Z"
                          />
                        </svg>
                      ),
                      title: "Smart Card",
                      description:
                        "Award and track customer rewards. Keep customers coming back for repeat purchases through points and discounts.",
                    },
                    {
                      icon: (
                        <svg viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M7,1A2,2 0 0,0 5,3V21A2,2 0 0,0 7,23H17A2,2 0 0,0 19,21V3A2,2 0 0,0 17,1H7M7,4H17V20H7V4M9,6V10H10.5V7.5H13V6H9M13.5,14V16.5H11V18H15V14H13.5Z"
                          />
                        </svg>
                      ),
                      title: "Colour Screen",
                      description:
                        "Communicate with your customers and get quick insights. Enjoy the convenience of an integrated messaging & feedback tool",
                    },
                    {
                      icon: (
                        <svg viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M14.71,7.71L10.41,12L14.71,16.29L9,22H8V14.41L3.41,19L2,17.59L7.59,12L2,6.41L3.41,5L8,9.59V2H9L14.71,7.71M10,5.83V9.59L11.88,7.71L10,5.83M11.88,16.29L10,14.41V18.17L11.88,16.29M22,8H20V11H18V8H16L19,4L22,8M22,16L19,20L16,16H18V13H20V16H22Z"
                          />
                        </svg>
                      ),
                      title: "Bluetooth",
                      description:
                        "Offering Bluetooth connectivity to virtually any iOS, Android, or Windows-based mobile device",
                    },
                  ],
                },
                {
                  title: "Dejavoo Z8",
                  img: dejavoo_z8_image,
                  description: (
                    <>
                      State of the art made, Dejavoo Z8 allows businesses to
                      cater to their customers mode of payment in a secure EMV
                      payment experience on every transaction. <br />
                      <br />
                      Payments accepted from Interac Flash°, Visa payWave and
                      Mastercard PayPass"' to mobile wallets like Apple Pays and
                      Google Pay... It combines robust features with an elegant
                      user interface and has been perfected for long use and
                      reliability!
                    </>
                  ),
                  specs: [
                    ["Processor", <>32 bits 400MHz Secure Microprocessor</>],
                    [
                      "Card Readers",
                      <>
                        Magstripe
                        <br />
                        Contact Card
                        <br />
                        Contactless Card
                      </>,
                    ],
                    ["Display", <>2.4" Color TFT-LCD</>],
                    ["KeyPad", <>15 Standard Keys, 6 Function Keys</>],
                    [
                      "Software",
                      <>
                        SDK, KMS, TMS, Simulator, CTOS library, ISO 8583
                        library, Crypto library
                      </>,
                    ],
                    [
                      "Certifications",
                      <>
                        Visa payWave, MasterCard PayPass, American Express, &
                        JCB J/Speedy
                      </>,
                    ],
                    [
                      "Memory",
                      <>
                        Flash / DDR RAM: 128MB / 64MB
                        <br />
                        Micro SD Socket: Optional
                      </>,
                    ],
                    ["Graphical Printer", <>2.25" x 80', 4" per second</>],
                    [
                      "Communications",
                      <>Ethernet, DIal-up Modem, 2 x RS232, 2 x USB, Wi-Fi</>,
                    ],
                    ["Security", <>PCI PTS, EMV L1 L2, TQM, PBOC L1 L2</>],
                  ],
                  features: [
                    {
                      title: "Top-Notch Security",
                      description:
                        "Rest easy with increased payment security via PCI-PTS certification and EMV Level 1 and Level 2 certification.",
                      icon: (
                        <svg viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M21,11C21,16.55 17.16,21.74 12,23C6.84,21.74 3,16.55 3,11V5L12,1L21,5V11M12,21C15.75,20 19,15.54 19,11.22V6.3L12,3.18L5,6.3V11.22C5,15.54 8.25,20 12,21M14.8,11V9.5C14.8,8.1 13.4,7 12,7C10.6,7 9.2,8.1 9.2,9.5V11C8.6,11 8,11.6 8,12.2V15.7C8,16.4 8.6,17 9.2,17H14.7C15.4,17 16,16.4 16,15.8V12.3C16,11.6 15.4,11 14.8,11M13.5,11H10.5V9.5C10.5,8.7 11.2,8.2 12,8.2C12.8,8.2 13.5,8.7 13.5,9.5V11Z"
                          />
                        </svg>
                      ),
                    },
                    {
                      title: "Remarkably Intuitive Interface",
                      description:
                        'A 2.4" colour screen LCD with backlight makes PIN entry and menu navigation easy and intuitive.',
                      icon: (
                        <svg viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M3.5,19A1.5,1.5 0 0,1 5,20.5A1.5,1.5 0 0,1 3.5,22A1.5,1.5 0 0,1 2,20.5A1.5,1.5 0 0,1 3.5,19M8.5,16A2.5,2.5 0 0,1 11,18.5A2.5,2.5 0 0,1 8.5,21A2.5,2.5 0 0,1 6,18.5A2.5,2.5 0 0,1 8.5,16M14.5,15C13.31,15 12.23,14.5 11.5,13.65C10.77,14.5 9.69,15 8.5,15C6.54,15 4.91,13.59 4.57,11.74C3.07,11.16 2,9.7 2,8A4,4 0 0,1 6,4L6.77,4.07C7.5,3.41 8.45,3 9.5,3C10.69,3 11.77,3.5 12.5,4.35C13.23,3.5 14.31,3 15.5,3C17.46,3 19.09,4.41 19.43,6.26C20.93,6.84 22,8.3 22,10A4,4 0 0,1 18,14L17.23,13.93C16.5,14.59 15.55,15 14.5,15M6,6A2,2 0 0,0 4,8A2,2 0 0,0 6,10C6.33,10 6.64,9.92 6.92,9.78C6.66,10.12 6.5,10.54 6.5,11A2,2 0 0,0 8.5,13C9.1,13 9.64,12.73 10,12.31V12.31L11.47,10.63L13,12.34V12.34C13.38,12.74 13.91,13 14.5,13C15.5,13 16.33,12.26 16.5,11.3C16.84,11.73 17.39,12 18,12A2,2 0 0,0 20,10A2,2 0 0,0 18,8C17.67,8 17.36,8.08 17.08,8.22C17.34,7.88 17.5,7.46 17.5,7A2,2 0 0,0 15.5,5C14.91,5 14.38,5.26 14,5.66L12.47,7.37L11,5.69V5.69C10.64,5.27 10.1,5 9.5,5C8.5,5 7.67,5.74 7.5,6.7C7.16,6.27 6.61,6 6,6M8.5,17.5A1,1 0 0,0 7.5,18.5A1,1 0 0,0 8.5,19.5A1,1 0 0,0 9.5,18.5A1,1 0 0,0 8.5,17.5Z"
                          />
                        </svg>
                      ),
                    },
                    {
                      title: "NFC Contactless Payments",
                      description:
                        "Provide a seamless payment experience with built-in EMV and NFC Contactless.",
                      icon: (
                        <svg viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M18,11H6A2,2 0 0,0 4,13V21A2,2 0 0,0 6,23H18A2,2 0 0,0 20,21V13A2,2 0 0,0 18,11M18,21H6V17H18V21M18,15H6V13H18V15M4.93,4.92L6.34,6.33C9.46,3.2 14.53,3.2 17.66,6.33L19.07,4.92C15.17,1 8.84,1 4.93,4.92M7.76,7.75L9.17,9.16C10.73,7.6 13.26,7.6 14.83,9.16L16.24,7.75C13.9,5.41 10.1,5.41 7.76,7.75Z"
                          />
                        </svg>
                      ),
                    },
                    {
                      title: "Tri-Comm Capability",
                      description:
                        "Connect to WiFi readily with a self discovering feature that lets the terminal find all available networks.",
                      icon: (
                        <svg viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M20.59 14.84L21.75 16.25L17 21L14.25 18L15.41 16.84L17 18.43L20.59 14.84M19.07 4.93L17.66 6.34C19.11 7.78 20 9.79 20 12V12.34C20.68 12.59 21.33 12.96 21.88 13.43C21.95 12.96 22 12.5 22 12C22 9.24 20.88 6.74 19.07 4.93M15.96 12.36C16.6 12.13 17.28 12 18 12C18 10.35 17.33 8.85 16.24 7.76L14.83 9.17C15.55 9.89 16 10.89 16 12C16 12.12 15.97 12.24 15.96 12.36M12 10C10.9 10 10 10.9 10 12S10.9 14 12 14 14 13.1 14 12 13.1 10 12 10M6.34 6.34L4.93 4.93C3.12 6.74 2 9.24 2 12S3.12 17.26 4.93 19.07L6.34 17.66C4.89 16.22 4 14.22 4 12C4 9.79 4.89 7.78 6.34 6.34M7.76 7.76C6.67 8.85 6 10.35 6 12S6.67 15.15 7.76 16.24L9.17 14.83C8.45 14.11 8 13.11 8 12S8.45 9.89 9.17 9.17L7.76 7.76Z"
                          />
                        </svg>
                      ),
                    },
                    {
                      title: "Multiple Payment Options",
                      description:
                        "Allow your customers to pay however they want to. from Apple Pay. Samsung Pay and Google Pay, to Visa payWave, Interac Flash and Mastercard PayPass.",
                      icon: (
                        <svg viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M6 13C3.79 13 2 14.79 2 17S3.79 21 6 21 10 19.21 10 17 8.21 13 6 13M6 19C4.9 19 4 18.1 4 17S4.9 15 6 15 8 15.9 8 17 7.1 19 6 19M6 3C3.79 3 2 4.79 2 7S3.79 11 6 11 10 9.21 10 7 8.21 3 6 3M12 5H22V7H12V5M12 19V17H22V19H12M12 11H22V13H12V11Z"
                          />
                        </svg>
                      ),
                    },
                  ],
                },
                {
                  title: "Dejavoo Z9",
                  img: dejavoo_z9_image,
                  description: (
                    <>
                      Designed with portability and security in mind, Dejavoo Z9
                      will blow your mind away. Fitted with top-notch payment
                      experience and Wi-Fi and GPRS Data network support, it
                      makes the perfect combination for any mobile merchant.{" "}
                      <br />
                      <br />
                      The Dejavoo Z9 has a remarkably intuitive interface with a
                      touch-screen that provides easy, user friendly, navigation
                      and the benefit of signature on the display. There's no
                      question about it. the Dejavoo Z9 is the perfect solution
                      for Pay-at-the-table, or just any place where WiFi is
                      available!
                    </>
                  ),
                  specs: [
                    ["Processor", <>32 bits 400MHz Secure Microprocessor</>],
                    [
                      "Card Readers",
                      <>
                        Magstripe
                        <br />
                        Contact Card
                        <br />
                        Contactless Card
                      </>,
                    ],
                    ["Display", <>3.5" Color TFT-LCD</>],
                    [
                      "Memory",
                      <>
                        Flash / DDR RAM: 128MB / 64MB
                        <br />
                        Micro SD Socket: Optional
                      </>,
                    ],
                    ["KeyPad", <>15 standard keys</>],
                    [
                      "Software",
                      <>
                        SDK, KMS, TMS, Simulator, CTOS library, ISO 8583
                        library, Crypto library
                      </>,
                    ],
                    [
                      "Weight",
                      <>
                        Handset: 0.74lbs
                        <br />
                        Base (optional): 0.30lbs
                      </>,
                    ],
                    ["Indicator", <>LED - 4 LEDs: X</>],
                    ["Power Supply", <>9V / 4A</>],
                    ["Security", <>PCI PTS, EMV L1 L2, TQM, PBOC L1 L2</>],
                  ],
                  features: [
                    {
                      title: "Top-Notch Security",
                      description:
                        "lComply with regulations and provide increased payment security with PCI-PTS certification and EMV Level 1 and Level 2 certification.",
                      icon: (
                        <svg viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M21,11C21,16.55 17.16,21.74 12,23C6.84,21.74 3,16.55 3,11V5L12,1L21,5V11M12,21C15.75,20 19,15.54 19,11.22V6.3L12,3.18L5,6.3V11.22C5,15.54 8.25,20 12,21M14.8,11V9.5C14.8,8.1 13.4,7 12,7C10.6,7 9.2,8.1 9.2,9.5V11C8.6,11 8,11.6 8,12.2V15.7C8,16.4 8.6,17 9.2,17H14.7C15.4,17 16,16.4 16,15.8V12.3C16,11.6 15.4,11 14.8,11M13.5,11H10.5V9.5C10.5,8.7 11.2,8.2 12,8.2C12.8,8.2 13.5,8.7 13.5,9.5V11Z"
                          />
                        </svg>
                      ),
                    },
                    {
                      title: "Remarkably Intuitive Interface",
                      description:
                        "Enjoy a multimedia experience like no other on a large 3.5-inch colour touch-screen.",
                      icon: (
                        <svg viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M3.5,19A1.5,1.5 0 0,1 5,20.5A1.5,1.5 0 0,1 3.5,22A1.5,1.5 0 0,1 2,20.5A1.5,1.5 0 0,1 3.5,19M8.5,16A2.5,2.5 0 0,1 11,18.5A2.5,2.5 0 0,1 8.5,21A2.5,2.5 0 0,1 6,18.5A2.5,2.5 0 0,1 8.5,16M14.5,15C13.31,15 12.23,14.5 11.5,13.65C10.77,14.5 9.69,15 8.5,15C6.54,15 4.91,13.59 4.57,11.74C3.07,11.16 2,9.7 2,8A4,4 0 0,1 6,4L6.77,4.07C7.5,3.41 8.45,3 9.5,3C10.69,3 11.77,3.5 12.5,4.35C13.23,3.5 14.31,3 15.5,3C17.46,3 19.09,4.41 19.43,6.26C20.93,6.84 22,8.3 22,10A4,4 0 0,1 18,14L17.23,13.93C16.5,14.59 15.55,15 14.5,15M6,6A2,2 0 0,0 4,8A2,2 0 0,0 6,10C6.33,10 6.64,9.92 6.92,9.78C6.66,10.12 6.5,10.54 6.5,11A2,2 0 0,0 8.5,13C9.1,13 9.64,12.73 10,12.31V12.31L11.47,10.63L13,12.34V12.34C13.38,12.74 13.91,13 14.5,13C15.5,13 16.33,12.26 16.5,11.3C16.84,11.73 17.39,12 18,12A2,2 0 0,0 20,10A2,2 0 0,0 18,8C17.67,8 17.36,8.08 17.08,8.22C17.34,7.88 17.5,7.46 17.5,7A2,2 0 0,0 15.5,5C14.91,5 14.38,5.26 14,5.66L12.47,7.37L11,5.69V5.69C10.64,5.27 10.1,5 9.5,5C8.5,5 7.67,5.74 7.5,6.7C7.16,6.27 6.61,6 6,6M8.5,17.5A1,1 0 0,0 7.5,18.5A1,1 0 0,0 8.5,19.5A1,1 0 0,0 9.5,18.5A1,1 0 0,0 8.5,17.5Z"
                          />
                        </svg>
                      ),
                    },
                    {
                      title: "Faster NFC Payments",
                      description:
                        "Offer your customers a seamless experience througn NFC contactless readers for Apple Payo, Google Waller, Samsung Pay, etc.",
                      icon: (
                        <svg viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M3 8C2.45 8 2 7.55 2 7S2.45 6 3 6H5.54C5.19 6.6 5 7.29 5 8H3M5 13H2C1.45 13 1 12.55 1 12S1.45 11 2 11H5V13M1 18C.448 18 0 17.55 0 17S.448 16 1 16H5C5 16.71 5.19 17.4 5.54 18H1M21 6H9C7.89 6 7 6.89 7 8V16C7 17.11 7.89 18 9 18H21C22.11 18 23 17.11 23 16V8C23 6.89 22.11 6 21 6M21 8V9H9V8H21M9 16L9 12H21L21 16L9 16Z"
                          />
                        </svg>
                      ),
                    },
                    {
                      title: "Multiple Payment Options",
                      description:
                        "Cater to your customers by allowing them to pay however they want to, from mobile wallets to EMV Chip & PIN, Swipe & Sign and contactless.",
                      icon: (
                        <svg viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M6 13C3.79 13 2 14.79 2 17S3.79 21 6 21 10 19.21 10 17 8.21 13 6 13M6 19C4.9 19 4 18.1 4 17S4.9 15 6 15 8 15.9 8 17 7.1 19 6 19M6 3C3.79 3 2 4.79 2 7S3.79 11 6 11 10 9.21 10 7 8.21 3 6 3M12 5H22V7H12V5M12 19V17H22V19H12M12 11H22V13H12V11Z"
                          />
                        </svg>
                      ),
                    },
                    {
                      title: "Signature on Display",
                      description:
                        "Touch screen provides easy, user friendly, navigation with the benefit of signature on the display, ideal for any merchant on the go!",
                      icon: (
                        <svg viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M7,1A2,2 0 0,0 5,3V21A2,2 0 0,0 7,23H17A2,2 0 0,0 19,21V3A2,2 0 0,0 17,1H7M7,4H17V20H7V4M9,6V10H10.5V7.5H13V6H9M13.5,14V16.5H11V18H15V14H13.5Z"
                          />
                        </svg>
                      ),
                    },
                  ],
                },
              ]}
            />
            <div className={styles.productsContainer}>
              <a className={baseStyles.heroCta} href={"/get-quote"}>
                Get Started
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
